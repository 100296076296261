<template>
	<v-list-item three-line :class="{ active: active }">
		<v-list-item-content @click="$emit('click')">
			<v-list-item-title>{{ text }}</v-list-item-title>
			<v-list-item-subtitle>
				<v-tooltip left>
					<template v-slot:activator="{on}">
						<v-progress-linear v-on="on" :value="completionRate"> </v-progress-linear>
					</template>
					{{$t("assessment.section_progress", {section: sectionName, progress: completionRate})}}
				</v-tooltip>
			</v-list-item-subtitle>
			<v-list-item-subtitle>
				<status-chip v-if="status" :id="response"></status-chip>
				<theme-chip v-if="theme" :id="section"></theme-chip>
			</v-list-item-subtitle>
		</v-list-item-content>
		<v-list-item-action v-if="buttons">
			<v-component icon :is="firstAction" :id="response"></v-component>
		</v-list-item-action>
		<v-list-item-action v-if="moreActions">
			<v-menu :close-on-content-click="false">
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
				</template>
				<v-list dense>
					<v-component
						v-for="action in actions"
						:key="action"
						:is="action"
						:id="response"
						:fileName="sectionName"
					></v-component>
				</v-list>
			</v-menu>
		</v-list-item-action>
	</v-list-item>
</template>

<style lang="less" scoped>
@import "../../../../assets/css/variables.less";
.v-list-item--dense .v-list-item__icon,
.v-list-item--dense .v-list-item__action,
.v-list--dense .v-list-item .v-list-item__icon,
.v-list--dense .v-list-item .v-list-item__action {
	margin: 4px;
}
.v-list-item__content {
	cursor: pointer;
}
.indent-1 {
	padding-left: 48px !important;
}
</style>
<style lang="less">
@import "../../../../assets/css/variables.less";
.active {
	background: #e4effa !important;
}
</style>

<script>
import ThemeChip from "@c/navigation/assessment/sections/ThemeChip";
import StatusChip from "@c/navigation/assessment/sections/StatusChip";
export default {
	name: "ResponseNavItem",
	props: {
		response: { type: String }, // the ID of the response,
		section: { type: String }, // the ID of the section,
		text: { type: String }, /// the text to display in the navigation
		status: { type: Boolean },
		theme: { type: Boolean },
		buttons: { type: Boolean, default: true },
		completion: { type: Number },
	},
	components: {
		StatusChip,
		ThemeChip,
		PdfExport: () => import("@c/survey/tools/PdfExport"),
		Delegate: () => import("@c/collaboration/Delegate"),
		Return: () => import("@c/survey/tools/Return"),
		Notes: () => import("@c/survey/tools/Notes"),
		Revoke: () => import("@c/collaboration/Revoke"),
		Complete: () => import("@c/survey/tools/Complete"),
	},
	computed: {
		current() {
			return this.$store.state.current;
		},
		active() {
			return this.current.response == this.response;
		},
		sectionName(){
			let section = this.$store.state.sections.data[this.section];
			return section ? section.name : "";
		},
		completionRate(){
			return this.completion.toFixed(2);
		},
		responseDetails() {
			return this.$store.state.sectionResponses.data[this.response] || {};
		},
		isLead() {
			return this.$store.getters.isLead;
		},
		isDelegate() {
			return this.$store.getters.isDelegate;
		},
		actions() {
			var self = this;
			var actions = [];

			if (self.responseDetails.status !== "complete") {
				if (self.isLead) {
					if (self.responseDetails.status !== "delegated") {
						if (self.responseDetails.completion_rate == 100) {
							if (this.sectionAllows("complete")) {
								actions.push("Complete");
							}
						}
						if (this.sectionAllows("delegation")) {
							actions.push("Delegate");
						}
						if (
							self.responseDetails.completion_rate < 100 &&
							this.sectionAllows("complete")
						) {
							actions.push("Complete");
						}
					} else {
						actions.push("Revoke");
					}
				} else {
					actions.push("Return");
				}
			}
			actions.push("Notes");
			actions.push("PdfExport");
			return actions;
		},
		firstAction() {
			return this.actions[0];
		},
		moreActions() {
			return this.buttons && this.actions.length > 1;
		},
	},
	methods: {
		sectionAllows(action) {
			return this.$store.getters.sectionAllows({
				id: this.responseDetails.section,
				action: action,
			});
		},
	},
};
</script>
